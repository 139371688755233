import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import CheckMarkIcon from '../CheckMarkIcon';

import Slant from '../Slant';

import './IntroSectionAdMechanic.scss';

const introScreen = require('../../assets/img/frogo-ad-mechanic-intro-screen.svg');

const IntroSectionAdMechanic = () => {
  return (
    <section className="IntroSectionAdMechanic-wrapper">
      <Container className="IntroSectionAdMechanic-content text-white">
        <Row className="pt-2">
          <Col xs={12} md={6}>
            <p className="lead text-left text-md-right">
              <img
                src={introScreen}
                alt="Intro-Screen"
                className="img-fluid IntroSectionAdMechanic-intro-screen"
              />
            </p>
          </Col>
          <Col xs={12} md={6} className="pt-4">
            <h6 className="mb-0">Ad Mechanic</h6>
            <h2 className="mb-0 IntroSectionAdMechanic-headline">
              Tausende Google Text Ads in wenigen Schritten erstellen
            </h2>
            <p className="lead IntroSectionAdMechanic-text">
              Mit dem{' '}
              <span className="IntroSectionAdMechanic-text-highlighted">
                FROGO AD Mechanic
              </span>{' '}
              können Sie{' '}
              <span className="IntroSectionAdMechanic-text-highlighted">
                Google Text Ads
              </span>{' '}
              auf Basis Ihrer Business-Daten{' '}
              <span className="IntroSectionAdMechanic-text-highlighted">
                vollautomatisch generieren und pflegen.
              </span>{' '}
              Dadurch sparen Sie Zeit und Geld.
            </p>
            <ul className="IntroSectionAdMechanic-sub-text IntroSectionAdMechanic-icon-list">
              <li className="d-flex">
                <div>
                  <CheckMarkIcon className="pr-1" height="1em" />
                </div>
                <span>
                  Passende Google Text Ads, Erweiterungen und Keywords
                </span>
              </li>
              <li className="d-flex">
                <div>
                  <CheckMarkIcon className="pr-1" height="1em" />
                </div>
                <span>Tägliche Aktualisierung Ihrer Text Ads</span>
              </li>
              <li className="d-flex">
                <div>
                  <CheckMarkIcon className="pr-1" height="1em" />
                </div>
                <span>Zeit sparen und Conversions steigern</span>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Slant style={{ color: '#f5f8fa' }} />
    </section>
  );
};

export default IntroSectionAdMechanic;
