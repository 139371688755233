import * as React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';
import SwappingNav from '../components/SwappingNav';
import TopNavbar from '../components/TopNavBar';
import IntroSectionAdMechanic from '../components/AdMechanic/IntroSectionAdMechanic';
import PrincipleSectionAdMechanic from '../components/AdMechanic/PrincipleSectionAdMechanic';
import PreviewSectionAdMechanic from '../components/AdMechanic/PreviewSectionAdMechanic';
import HowToSectionAdMechanic from '../components/AdMechanic/HowToSectionAdMechanic';
import PricingSectionAdMechanic from '../components/AdMechanic/PricingSectionAdMechanic';
import BenefitsSectionAdMechanic from '../components/AdMechanic/BenefitsSectionAdMechanic';

import Footer from '../components/Footer';
import IeWarning from '../components/IeWarning';

const metaKeywords =
  'frogo ad, frogoad, data driven marketing, google adwords, adwords, google ads, ads,' +
  'datengetrieben, feed, textanzeige, textanzeigen, erweiterte textanzeigen, expanded text ads, call out extensions,' +
  'sitelink extensions, price extensions, erweiterungen mit zusatzinformationen, sitelink-erweiterungen, preiserweiterungen,' +
  'automatisierung';

export default class extends React.Component<{}, {}> {
  public render() {
    return (
      <Layout>
        <Helmet>
          <title>Ad Mechanic</title>
          <meta name="keywords" content={metaKeywords} />
        </Helmet>
        <SwappingNav
          scrollInHeight={15}
          initialNavbar={<TopNavbar transparent={true} />}
          swappedNavbar={<TopNavbar />}
        />
        <main>
          <IntroSectionAdMechanic />
          <PrincipleSectionAdMechanic />
          <PreviewSectionAdMechanic />
          <HowToSectionAdMechanic />
          <PricingSectionAdMechanic />
          <BenefitsSectionAdMechanic />
          <Footer />
        </main>
        <IeWarning />
      </Layout>
    );
  }
}
