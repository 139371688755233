import * as React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardText
} from 'reactstrap';

import './PricingSectionAdMechanic.scss';

import CheckMarkIcon from '../CheckMarkIcon';

import { Link } from 'gatsby';

const PricingSectionAdMechanic = () => {
  return (
    <section className="PricingSectionAdMechanic">
      <Container className="PricingSectionAdMechanic-content">
        <h5 className="text-uppercase text-center PricingSectionAdMechanic-headline">
          Nutzungsbasierte Abrechnung
        </h5>
        <p className="pt-4 mx-5 lead text-center PricingSectionAdMechanic-content">
          Beginnen Sie mit dem <b>Starter Kit</b> von FROGO&nbsp;AD Mechanic, um
          kostenlos und unverbindlich Text Ads zu generieren.
        </p>
        <Row className="equal-height">
          <Col xs={12} lg={3} className="pt-3">
            <Card className="PricingSectionAdMechanic-card card-equal-height fg-card-shadow">
              <CardHeader className="PricingSectionAdMechanic-card-head PricingSectionAdMechanic-card-head-starter-kit text-center d-flex flex-column justify-content-center">
                <div className="PricingSectionAdMechanic-card-head-h1">
                  Starter Kit
                </div>
                <div className="PricingSectionAdMechanic-card-head-h2">0 €</div>
                <div className="PricingSectionAdMechanic-card-head-h3">
                  einmalig | statt 898 €
                </div>
              </CardHeader>
              <CardBody className="d-flex flex-column PricingSectionAdMechanic-card-body-starter-kit">
                <div>
                  <ul className="PricingSectionAdMechanic-icon-list">
                    <li className="d-flex" style={{ lineHeight: '1.2' }}>
                      <div>
                        <CheckMarkIcon className="pr-1" height="0.9em" />
                      </div>
                      <div>
                        Persönliches Text&nbsp;Ads <b>Template</b> definieren
                      </div>
                    </li>
                    <li className="mt-3 d-flex" style={{ lineHeight: '1.2' }}>
                      <div>
                        <CheckMarkIcon className="pr-1" height="0.9em" />
                      </div>
                      <div>Tausende individuelle Text&nbsp;Ads generieren</div>
                    </li>
                  </ul>
                </div>
                <div className="mt-auto">
                  <a
                    href="#account-request"
                    className="mx-4 d-flex justify-content-center btn btn-sm btn-primary text-white"
                  >
                    Kostenlos starten
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} lg={9} className="px-0">
            <Container className="d-flex flex-column">
              <Row className="equal-height">
                <Col xs={12} lg={true} className="pt-3 px-lg-1">
                  <Card className="PricingSectionAdMechanic-card card-equal-height fg-card-shadow">
                    <CardHeader className="PricingSectionAdMechanic-card-head PricingSectionAdMechanic-card-head-package d-flex flex-column justify-content-center text-center">
                      <div className="PricingSectionAdMechanic-card-head-h1">
                        Small
                      </div>
                      <div className="PricingSectionAdMechanic-card-head-h2">
                        0 €
                      </div>
                      <div className="PricingSectionAdMechanic-card-head-h3">
                        monatlich
                      </div>
                    </CardHeader>
                    <CardBody className="d-flex align-items-center justify-content-center text-center">
                      <CardText>
                        Bis zu <b>1.000 €</b> <br />
                        Adspend pro Monat
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} lg={true} className="pt-3 px-lg-1">
                  <Card className="PricingSectionAdMechanic-card card-equal-height fg-card-shadow">
                    <CardHeader className="PricingSectionAdMechanic-card-head PricingSectionAdMechanic-card-head-package d-flex flex-column justify-content-center text-center">
                      <div className="PricingSectionAdMechanic-card-head-h1">
                        Medium
                      </div>
                      <div className="PricingSectionAdMechanic-card-head-h2">
                        298 €
                      </div>
                      <div className="PricingSectionAdMechanic-card-head-h3">
                        monatlich
                      </div>
                    </CardHeader>
                    <CardBody className="py-4 d-flex align-items-center justify-content-center text-center">
                      <CardText>
                        Bis zu <b>5.000 €</b> <br />
                        Adspend pro Monat
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} lg={true} className="pt-3 px-lg-1">
                  <Card className="PricingSectionAdMechanic-card card-equal-height fg-card-shadow">
                    <CardHeader className="PricingSectionAdMechanic-card-head PricingSectionAdMechanic-card-head-package d-flex flex-column justify-content-center text-center">
                      <div className="PricingSectionAdMechanic-card-head-h1">
                        Large
                      </div>
                      <div className="PricingSectionAdMechanic-card-head-h2">
                        598 €
                      </div>
                      <div className="PricingSectionAdMechanic-card-head-h3">
                        <b style={{ color: '#ffffff' }}>+ 3,5 % Adspend</b> |{' '}
                        monatlich
                      </div>
                    </CardHeader>
                    <CardBody className="d-flex align-items-center justify-content-center text-center">
                      <CardText>
                        <b>Unbegrenzt</b> <br />
                        Adspend pro Monat
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="flex-grow-1">
                <Col className="pt-3 pt-lg-2 px-lg-1">
                  <Card className="h-100 PricingSectionAdMechanic-card-body fg-card-shadow">
                    <CardBody className="d-flex align-items-center">
                      <Row>
                        <Col
                          xs={12}
                          sm={6}
                          className="pl-4 d-flex align-items-center PricingSectionAdMechanic-subcard-content"
                        >
                          Es fallen nur dann Gebühren an, wenn Sie mit den durch
                          Ad&nbsp;Mechanic generierten Text Ads einen Adspend
                          von 1.000&nbsp;€ pro Monat überschreiten.
                        </Col>
                        <Col xs={12} sm={6}>
                          <div className="d-flex align-items-center">
                            <ul className="mt-2 mt-lg-0 mb-0 pl-3 pl-lg-0 font-weight-bold PricingSectionAdMechanic-subcard-content">
                              <li className="d-flex">
                                <div>
                                  <CheckMarkIcon
                                    className="pr-1"
                                    height="0.9em"
                                  />
                                </div>
                                <div>
                                  Synchronisation mit Ihren Business-Daten
                                </div>
                              </li>
                              <li className="d-flex">
                                <div>
                                  <CheckMarkIcon
                                    className="pr-1"
                                    height="0.9em"
                                  />
                                </div>
                                <div>
                                  Tägliche Aktualisierung Ihrer Text&nbsp;Ads
                                </div>
                              </li>
                              <li className="d-flex">
                                <div>
                                  <CheckMarkIcon
                                    className="pr-1"
                                    height="0.9em"
                                  />
                                </div>
                                <div>Monatlich kündbar</div>
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PricingSectionAdMechanic;
