import * as React from 'react';
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';

import './PreviewSectionAdMechanic.scss';

const textAdPreview = require('../../assets/img/frogo-ad-mechanic-text-ads-result-1.svg');
const priceExtensionPreview = require('../../assets/img/frogo-ad-mechanic-text-ads-result-2.svg');
const sitelinkExtensionPreview = require('../../assets/img/frogo-ad-mechanic-text-ads-result-3.svg');
const keywordsPreview = require('../../assets/img/frogo-ad-mechanic-text-ads-result-4.svg');

interface PreviewSectionAdMechanicState {
  activeTab: string;
}

class PreviewSectionAdMechanic extends React.Component<
  {},
  PreviewSectionAdMechanicState
> {
  constructor(props: {}) {
    super(props);

    this.state = {
      activeTab: '1'
    };
  }

  render() {
    return (
      <section className="PreviewSectionAdMechanic">
        <Container className="PreviewSectionAdMechanic-content">
          <h5 className="text-uppercase text-center PreviewSectionAdMechanic-headline">
            Das Ergebnis
          </h5>
          <Row className="pt-4">
            <Col>
              <Nav pills={true} className="justify-content-center">
                <NavItem className="PreviewSectionAdMechanic-nav-item">
                  <NavLink
                    active={this.state.activeTab === '1'}
                    onClick={() => this.setState({ activeTab: '1' })}
                    className="PreviewSectionAdMechanic-nav-link"
                  >
                    Expanded Text Ads
                  </NavLink>
                </NavItem>
                <NavItem className="PreviewSectionAdMechanic-nav-item">
                  <NavLink
                    active={this.state.activeTab === '2'}
                    onClick={() => this.setState({ activeTab: '2' })}
                    className="PreviewSectionAdMechanic-nav-link"
                  >
                    Preiserweiterungen
                  </NavLink>
                </NavItem>
                <NavItem className="PreviewSectionAdMechanic-nav-item">
                  <NavLink
                    active={this.state.activeTab === '3'}
                    onClick={() => this.setState({ activeTab: '3' })}
                    className="PreviewSectionAdMechanic-nav-link"
                  >
                    Sitelink-Erweiterungen
                  </NavLink>
                </NavItem>
                <NavItem className="PreviewSectionAdMechanic-nav-item">
                  <NavLink
                    active={this.state.activeTab === '4'}
                    onClick={() => this.setState({ activeTab: '4' })}
                    className="PreviewSectionAdMechanic-nav-link"
                  >
                    Keywords
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col xs={12} lg={6} className="pt-4">
                      <img
                        src={textAdPreview}
                        className="img-fluid PreviewSectionAdMechanic-image"
                        alt="Textanzeige (Preview)"
                      />
                    </Col>
                    <Col xs={12} lg={6} className="pt-4 d-flex flex-column">
                      <div className="w-100 d-flex flex-nowrap align-items-center PreviewSectionAdMechanic-description-row">
                        <div className="flex-shrink-0 p-1">
                          <span className="PreviewSectionAdMechanic-circle-text color1">
                            1
                          </span>
                        </div>
                        <div className="pl-2">
                          Automatisch erzeugter Anzeigentitel, Anzeigen-URL* und
                          Beschreibung mit <b>Produktbrand</b> und{' '}
                          <b>&#8209;kategorie</b>
                        </div>
                      </div>
                      <div className="mt-2 w-100 d-flex flex-nowrap align-items-center PreviewSectionAdMechanic-description-row">
                        <div className="flex-shrink-0 p-1">
                          <span className="PreviewSectionAdMechanic-circle-text color2">
                            2
                          </span>
                        </div>
                        <div className="pl-2">
                          Dynamische Einspielung tagesaktueller Preise und
                          Rabatte
                        </div>
                      </div>
                      <div className="mt-2 w-100 d-flex flex-nowrap align-items-center PreviewSectionAdMechanic-description-row">
                        <div className="flex-shrink-0 p-1">
                          <span className="PreviewSectionAdMechanic-circle-text color3">
                            3
                          </span>
                        </div>
                        <div className="pl-2">
                          Dynamisches Element als Callout (z.B. Anzahl an
                          Produkten, welche versandkostenfrei lieferbar sind)
                        </div>
                      </div>
                      <span className="mt-auto PreviewSectionAdMechanic-note">
                        <small>
                          * Passgenau generierte Ziel-Url über die Shop-Suche
                        </small>
                      </span>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col xs={12} lg={6} className="pt-4">
                      <img
                        src={priceExtensionPreview}
                        className="img-fluid PreviewSectionAdMechanic-image"
                        alt="Preiserweiterung (Preview)"
                      />
                    </Col>
                    <Col xs={12} lg={6} className="pt-4 d-flex flex-column">
                      <div className="w-100 d-flex flex-nowrap align-items-center PreviewSectionAdMechanic-description-row">
                        <div className="flex-shrink-0 p-1">
                          <span className="PreviewSectionAdMechanic-circle-text color4">
                            4
                          </span>
                        </div>
                        <div className="pl-2">
                          Automatisch erzeugte <b>Preiserweiterung</b> mit{' '}
                          <b>verwandten Produktbrands</b> der jeweiligen
                          Kategorie
                        </div>
                      </div>
                      <div className="mt-2 w-100 d-flex flex-nowrap align-items-center PreviewSectionAdMechanic-description-row">
                        <div className="flex-shrink-0 p-1">
                          <span className="PreviewSectionAdMechanic-circle-text color5">
                            5
                          </span>
                        </div>
                        <div className="pl-2">
                          Passend eingefügte <b>Mindestpreise</b>
                        </div>
                      </div>
                      <div className="mt-2 w-100 d-flex flex-nowrap align-items-center PreviewSectionAdMechanic-description-row">
                        <div className="flex-shrink-0 p-1">
                          <span className="PreviewSectionAdMechanic-circle-text color6">
                            6
                          </span>
                        </div>
                        <div className="pl-2">
                          Einspielung <b>dynamischer Elemente</b> (z.B.
                          ”Versandkostenfrei!”, wenn alle “Olympus Kameras” über
                          Versandkostengrenze)
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col xs={12} lg={6} className="pt-4">
                      <img
                        src={sitelinkExtensionPreview}
                        className="img-fluid PreviewSectionAdMechanic-image"
                        alt="Sitelink-Erweiterung (Preview)"
                      />
                    </Col>
                    <Col xs={12} lg={6} className="pt-4 d-flex flex-column">
                      <div className="w-100 d-flex flex-nowrap align-items-center PreviewSectionAdMechanic-description-row">
                        <div className="flex-shrink-0 p-1">
                          <span className="PreviewSectionAdMechanic-circle-text color7">
                            7
                          </span>
                        </div>
                        <div className="pl-2">
                          Automatisch erzeugte <b>Sitelink-Titel</b> mit{' '}
                          <b>verwandten Kategorien</b> der jeweiligen
                          Produktbrand
                        </div>
                      </div>
                      <div className="mt-2 w-100 d-flex flex-nowrap align-items-center PreviewSectionAdMechanic-description-row">
                        <div className="flex-shrink-0 p-1">
                          <span className="PreviewSectionAdMechanic-circle-text color8">
                            8
                          </span>
                        </div>
                        <div className="pl-2">
                          Passend eingefügte <b>statische</b> und{' '}
                          <b>dynamische Elemente</b> als{' '}
                          <b>Sitelink-Beschreibung</b> (maximaler Rabatt,
                          Produktanzahl, Mindestpreis etc.)
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col xs={12} lg={6} className="pt-4">
                      <img
                        src={keywordsPreview}
                        className="img-fluid PreviewSectionAdMechanic-image"
                        alt="Keywords (Preview)"
                      />
                    </Col>
                    <Col xs={12} lg={6} className="pt-4 d-flex flex-column">
                      <div className="w-100 d-flex flex-nowrap align-items-center PreviewSectionAdMechanic-description-row">
                        <div className="flex-shrink-0 p-1">
                          <span className="PreviewSectionAdMechanic-circle-text color9">
                            9
                          </span>
                        </div>
                        <div className="pl-2">
                          Automatisch kombinierte, <b>zielgenaue Keywords</b>*
                          und <b>Synonyme</b>
                        </div>
                      </div>
                      <span className="mt-4 PreviewSectionAdMechanic-note">
                        <small>
                          * Unter Verwendung geeigneter Keyword-Optionen
                          (Phrase-Match, Modified Broad Match etc.)
                        </small>
                      </span>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default PreviewSectionAdMechanic;
