import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './HowToSectionAdMechanic.scss';

const HowToSectionAdMechanic = () => {
  return (
    <section className="HowToSectionAdMechanic text-white">
      <Container>
        <Row>
          <Col xs={12}>
            <h5 className="text-uppercase text-center HowToSectionAdMechanic-headline">
              Wie funktioniert's?
            </h5>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col xs={12} md={6} className="pt-4">
            <Row>
              <Col xs={12} lg={3} className="text-left text-lg-right">
                <span className="HowToSectionAdMechanic-step-number">1</span>
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2 className="HowToSectionAdMechanic-content-headline">
                  Autorisierung
                </h2>
                <p className="lead HowToSectionAdMechanic-content">
                  Um Text Ads für Google automatisiert zu generieren und täglich
                  zu aktualisieren, benötigt FROGO&nbsp;AD Zugriff auf Ihren{' '}
                  <b>Google Shopping Feed</b> sowie auf Ihr{' '}
                  <b>Google Ads Konto.</b>
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="pt-4">
            <Row>
              <Col xs={12} lg={3} className="text-left text-lg-right">
                <span className="HowToSectionAdMechanic-step-number">2</span>
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2 className="HowToSectionAdMechanic-content-headline">
                  Setup
                </h2>
                <p className="lead HowToSectionAdMechanic-content">
                  Damit die Automatisierung reibungslos funktioniert, bereiten
                  wir Ihre Business-Daten auf und nehmen die initiale
                  Konfiguration vor. Nach Fertigstellung erhalten Sie Ihr
                  persönliches Text&nbsp;Ads Template.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-sm-5">
          <Col xs={12} md={6} className="pt-4">
            <Row>
              <Col xs={12} lg={3} className="text-left text-lg-right">
                <span className="HowToSectionAdMechanic-step-number">3</span>
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2 className="HowToSectionAdMechanic-content-headline">
                  Template
                </h2>
                <p className="lead HowToSectionAdMechanic-content">
                  Definieren Sie durch ein einfaches Template in wenigen Minuten
                  die zugrundeliegende Struktur Ihrer Google Text&nbsp;Ads. Die
                  FROGO&nbsp;AD Experten stehen Ihnen dabei gerne zur Seite.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="pt-4">
            <Row>
              <Col xs={12} lg={3} className="text-left text-lg-right">
                <span className="HowToSectionAdMechanic-step-number">4</span>
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2 className="HowToSectionAdMechanic-content-headline">
                  Automatisierung
                </h2>
                <p className="lead HowToSectionAdMechanic-content">
                  Über Ihren Google Shopping Feed und das zuvor definierte
                  Template erstellt Ad&nbsp;Mechanic vollautomatisch Ihre Google
                  Text&nbsp;Ads. Alle dynamischen Elemente werden täglich
                  aktualisiert.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HowToSectionAdMechanic;
