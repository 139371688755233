import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './PrincipleSectionAdMechanic.scss';

const data = require('../../assets/img/frogo-ad-mechanic-data-icon.svg');
const template = require('../../assets/img/frogo-ad-mechanic-template-icon.svg');
const textAds = require('../../assets/img/frogo-ad-mechanic-text-ads-icon.svg');
const sync = require('../../assets/img/frogo-ad-mechanic-sync-icon.svg');

const PrincipleSectionAdMechanic = () => {
  return (
    <section className="PrincipleSectionAdMechanic">
      <Container className="PrincipleSectionAdMechanic-content">
        <Row>
          <Col>
            <h5 className="text-uppercase text-center PrincipleSectionAdMechanic-headline">
              Das Grundprinzip
            </h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
              <div className="pt-2 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                <div className="pt-2 px-3 d-flex flex-column align-items-center">
                  <img
                    src={data}
                    alt="Datenfeed-Icon"
                    className="PrincipleSectionAdMechanic-icon"
                  />
                  <h5 className="PrincipleSectionAdMechanic-headline-text">
                    Business-Daten
                  </h5>
                  <p className="PrincipleSectionAdMechanic-subheadline-text">
                    Google Shopping Feed
                    <br />
                    als Datenquelle
                  </p>
                </div>
                <div className="pt-2 px-3 d-flex flex-column align-items-center">
                  <img
                    src={template}
                    alt="Template-Icon"
                    className="PrincipleSectionAdMechanic-icon"
                  />
                  <h5 className="PrincipleSectionAdMechanic-headline-text">
                    Text Ads Template
                  </h5>
                  <p className="PrincipleSectionAdMechanic-subheadline-text">
                    Vordefinierte Parameter mit
                    <br />
                    individueller Anpassung
                  </p>
                </div>
              </div>
              <div className="pt-2 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                <div className="pt-2 px-3 d-flex flex-column align-items-center">
                  <img
                    src={textAds}
                    alt="Text-Ads-Icon"
                    className="PrincipleSectionAdMechanic-icon"
                  />
                  <h5 className="PrincipleSectionAdMechanic-headline-text">
                    Automatische Generierung
                  </h5>
                  <p className="PrincipleSectionAdMechanic-subheadline-text">
                    Erstellung tausender zielgenauer
                    <br />
                    Google Text Ads
                  </p>
                </div>
                <div className="pt-2 px-3 d-flex flex-column align-items-center">
                  <img
                    src={sync}
                    alt="Sync-Icon"
                    className="PrincipleSectionAdMechanic-icon"
                  />
                  <h5 className="PrincipleSectionAdMechanic-headline-text">
                    Automatische Aktualisierung
                  </h5>
                  <p className="PrincipleSectionAdMechanic-subheadline-text">
                    Tagesaktuelle Updates dynamischer
                    <br />
                    Text Ads Elemente
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-left text-lg-center">
            <p className="pt-4 lead PrincipleSectionAdMechanic-content">
              Mithilfe von <b>Business-Daten</b> aus Ihrem{' '}
              <b>Google Shopping Feed</b> und einem <b>einfachen Template</b>{' '}
              generiert Ad&nbsp;Mechanic{' '}
              <b>tausende individuelle Google Text&nbsp;Ads</b>. Durch{' '}
              <b>Synchronisation</b> mit Ihren Business-Daten hält
              Ad&nbsp;Mechanic in Zukunft alle Ihre Text&nbsp;Ads{' '}
              <b>vollautomatisch</b> auf dem <b>neuesten Stand</b>.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PrincipleSectionAdMechanic;
