import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './BenefitsSectionAdMechanic.scss';

const cogIcon = require('../../assets/img/frogo-ad-mechanic-cog-icon.svg');
const controlIcon = require('../../assets/img/frogo-ad-mechanic-control-icon.svg');
const integrationIcon = require('../../assets/img/frogo-ad-mechanic-integration-icon.svg');
const freeIcon = require('../../assets/img/frogo-icon-analyse-free.svg');

const BenefitsSectionAdMechanic = () => {
  return (
    <section className="BenefitsSectionAdMechanic text-white">
      <Container>
        <Row>
          <Col xs={12}>
            <h5 className="text-uppercase text-center BenefitsSectionAdMechanic-headline">
              Ihre Vorteile
            </h5>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col xs={12} md={6} className="pt-4 pr-lg-4">
            <Row>
              <Col xs={12} lg={3} className="pr-lg-0 text-center">
                <img
                  src={cogIcon}
                  alt="Automatic-Icon"
                  className="BenefitsSectionAdMechanic-icon"
                />
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2 className="BenefitsSectionAdMechanic-content-headline">
                  Text Ads generieren
                </h2>
                <p className="lead BenefitsSectionAdMechanic-content">
                  In wenigen Schritten lassen sich mit Ad&nbsp;Mechanic
                  vollautomatisch tausende individuelle Google Text Ads für
                  Ihren Onlineshop generieren. Dadurch sparen sie Zeit und
                  reduzieren Ihren Aufwand für die Ad-Erstellung erheblich.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="pt-4 pl-lg-4">
            <Row>
              <Col xs={12} lg={3} className="text-center">
                <img
                  src={controlIcon}
                  alt="Control-Icon"
                  className="BenefitsSectionAdMechanic-icon"
                />
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2 className="BenefitsSectionAdMechanic-content-headline">
                  Kontrolle behalten
                </h2>
                <p className="lead BenefitsSectionAdMechanic-content">
                  Die Erstellung Ihrer Text Ads geschieht nach Kriterien, die
                  Sie selbst festlegen (Template). Ad&nbsp;Mechanic generiert
                  und aktualisiert Ihre Textanzeigen. Kampagnenmanagement und
                  &#8209;optimierung bleiben weiterhin in Ihrer Hand.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-sm-5">
          <Col xs={12} md={6} className="pt-4 pr-lg-4">
            <Row>
              <Col xs={12} lg={3} className="text-center">
                <img
                  src={integrationIcon}
                  alt="Integration-Icon"
                  className="BenefitsSectionAdMechanic-icon"
                />
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2 className="BenefitsSectionAdMechanic-content-headline">
                  Top aktuelle Anzeigen
                </h2>
                <p className="lead BenefitsSectionAdMechanic-content">
                  Durch regelmäßige Synchronisation zwischen IIhren Shop-Daten
                  und den generierten Google Text Ads bleiben Ihre Anzeigen
                  stets top aktuell. So wird beispielsweise der Preis
                  aktualisiert, sobald dieser sich ändert oder die Anzeige
                  pausiert, wenn keine lieferbaren Produkte mehr verfügbar sind.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="pt-4 pl-lg-4">
            <Row>
              <Col xs={12} lg={3} className="text-center">
                <img
                  src={freeIcon}
                  alt="Free-of-charge-Icon"
                  className="BenefitsSectionAdMechanic-icon"
                />
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2 className="BenefitsSectionAdMechanic-content-headline">
                  Kostenlos testen
                </h2>
                <p className="lead BenefitsSectionAdMechanic-content">
                  Generieren und aktualisieren Sie mit Ad&nbsp;Mechanic
                  kostenlos Google Text Ads. Erst wenn Ihre erzeugten Google
                  Text Ads einen monatlichen Adspend von 1000&nbsp;€
                  überschreiten, wird Ad&nbsp;Mechanic kostenpflichtig. Keine
                  Gebühren ohne ausdrückliche Zustimmung.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BenefitsSectionAdMechanic;
